<template>
  <!-- 商品列表页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <a-col :md="6" :sm="24">
                  <!-- 商品搜索 -->
                  <a-form-item label="关键词搜索">
                    <a-input v-model.trim="queryParam.keywords" allow-clear placeholder="请输入商品名称、关键字" />
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <!-- 商品分类 -->
                  <a-form-item label="所属分类">
                    <a-tree-select
                      v-model="queryParam.goodsCategoryId"
                      style="width: 100%"
                      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                      placeholder="请选择"
                      allow-clear
                    >
                      <a-tree-select-node
                        v-for="parentItem in classList"
                        :key="parentItem.id"
                        :value="parentItem.id"
                        :title="parentItem.name"
                        :selectable="false"
                      >
                        <a-tree-select-node
                          v-for="item in parentItem.childrenList"
                          :key="item.id"
                          :selectable="true"
                          :value="item.id"
                          :title="item.name"
                        />
                      </a-tree-select-node>
                    </a-tree-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item label="时间">
                    <a-range-picker allow-clear v-model="queryParam.time" show-time style="width: 320px" />
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="searchTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => ((queryParam = {}), searchTableData())"
                    >重置</a-button
                  >
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 添加商品按钮 -->
          <div class="table-operator">
            <a-button type="primary" @click="handleBulkRepricing">批量改价</a-button>
          </div>
          <!-- 表格 -->
          <a-table
            :row-selection="rowSelection"
            :rowClassName="rowClassName"
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300, y: 800 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <span slot="specName" slot-scope="text, record">
              <a-input
                v-model="record.name"
                placeholder="请输入"
                style="width: 100%"
                @change="handleSetRowClassName(record)"
              />
            </span>
            <span slot="price" slot-scope="text, record">
              <a-input-number
                :min="0"
                :max="9999999"
                :precision="2"
                v-model="record.price"
                placeholder="请输入"
                @change="handleSetRowClassName(record)"
              />
            </span>
            <span slot="minPrice" slot-scope="text, record">
              <a-input-number
                :min="0"
                :max="9999999"
                :precision="2"
                v-model="record.minPrice"
                placeholder="请输入"
                @change="handleSetRowClassName(record)"
              />
            </span>
            <span slot="costPrice" slot-scope="text, record">
              <a-input-number
                :min="0"
                :max="9999999"
                :precision="2"
                v-model="record.costPrice"
                placeholder="请输入"
                @change="handleSetRowClassName(record)"
              />
            </span>
            <span slot="commissionRatio" slot-scope="text, record">
              <a-input-number
                :min="0"
                :max="9999999"
                :precision="2"
                :step="0.1"
                v-model="record.commissionRatio"
                placeholder="请输入"
                @change="handleSetRowClassName(record)"
              />
            </span>
            <span slot="stock" slot-scope="text, record">
              <a-input-number
                :min="0"
                :max="9999999"
                :precision="0"
                v-model="record.stock"
                placeholder="请输入"
                @change="handleSetRowClassName(record)"
              />
            </span>
            <!-- 商品图片插槽 -->
            <span slot="commodityImg" slot-scope="text, record">
              <viewer :images="[record.image]">
                <img
                  :src="record.image"
                  style="width: 50px; height: 50px; border: 0.5px solid #ccc; padding: 5px; margin: 3px"
                  alt=""
                />
              </viewer>
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="handlePriceAdjustment(record)"> 改价 </a>
              <a-divider type="vertical" />
              <a-tooltip>
                <template slot="title"> 将规格同步到其它区域 </template>
                <a @click="$refs.synchronizationForm.init(record)">同步</a>
              </a-tooltip>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>

    <synchronization-form ref="synchronizationForm" @ok="initTableData"></synchronization-form>
  </div>
</template>

<script>
import {
  querySpecListByCommodityId,
  querySpecValueListBySpcId,
  commodityDelete,
  deleteGoodsSpecValue,
  deleteGoodsSpec,
  platformLowerShelfGoods,
  goodsSpecPage,
  updatePriceBatch,
} from '@/api/modular/mallLiving/commodity/commodityList'
import { findCategoryListByStore } from '@/api/modular/mallLiving/commodity/commodityClass'
import SynchronizationForm from '@/views/system/areaList/synchronizationForm.vue' //查询 分类及二级分类

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '80px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商品名称',
    dataIndex: 'goodsName',
    width: '260px',
  },
  {
    title: '规格名称',
    dataIndex: 'name',
    width: '260px',
    scopedSlots: {
      customRender: 'specName',
    },
  },
  {
    title: '封面图',
    dataIndex: 'image',
    key: 'image',
    align: 'center',
    scopedSlots: {
      customRender: 'commodityImg',
    },
    width: '100px',
  },
  {
    title: '售价',
    key: 'price',
    dataIndex: 'price',
    align: 'center',
    width: '160px',
    scopedSlots: {
      customRender: 'price',
    },
  },
  {
    title: '成本价',
    key: 'costPrice',
    dataIndex: 'costPrice',
    align: 'center',
    width: '160px',
    scopedSlots: {
      customRender: 'costPrice',
    },
  },
  {
    title: '最低价',
    key: 'minPrice',
    dataIndex: 'minPrice',
    align: 'center',
    width: '160px',
    scopedSlots: {
      customRender: 'minPrice',
    },
  },
  {
    title: '业务员卖出平台的商品，利润拿出去和代理商分的比例',
    key: 'commissionRatio',
    dataIndex: 'commissionRatio',
    align: 'center',
    width: '160px',
    scopedSlots: {
      customRender: 'commissionRatio',
    },
  },
  {
    title: '库存',
    key: 'stock',
    dataIndex: 'stock',
    align: 'center',
    width: '86px',
    scopedSlots: {
      customRender: 'stock',
    },
  },
  {
    title: '操作',
    align: 'center',
    dataIndex: 'action',
    width: '210px',
    key: 'action',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export default {
  components: { SynchronizationForm },
  data() {
    return {
      visible1: false,
      confirmLoading1: false,
      columns,
      currentItem: null,
      currentSpecItem: null,
      data: [],
      originalData: [],
      loading: false,
      confirmLoading2: false,
      visible2: false,
      page: {
        pageNo: 1,
        pageSize: 100,
      },
      total: 0,
      classList: [], //顶部商品分类筛选数
      queryParam: {
        time: [],
        commodityClass: undefined, //商品分类
        commoditySearch: undefined, //商品搜索
      },
      specPage: {
        pageNumber: 1,
        pageSize: 10,
      },
      specValuePage: {
        pageNumber: 1,
        pageSize: 10,
      },
      specTotal: 0,
      specValueTotal: 0,
      specValueColumns: [
        {
          title: '规格属性名称',
          dataIndex: 'specValueName',
          align: 'center',
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 200,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      specColumns: [
        {
          title: '规格名称',
          dataIndex: 'specName',
          align: 'center',
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 200,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      specDatas: [],
      specValueDatas: [],
      areaId: '',
      selectedRowKeys: [],
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys) => {
          this.selectedRowKeys = selectedRowKeys
        },
      }
    },
  },
  mounted() {
    console.log('this.$store.getters.userInfo', this.userInfo)
    this.areaId = this.$route.query.areaId
    this.initTableData()

    this.getCommodityClassList() //获取下拉列表分类信息
  },
  methods: {
    rowClassName(record, index) {
      console.log('record', record, index)
      return record.rowClassName
    },
    handleSetRowClassName(record) {
      record.rowClassName = 'row-success'
      this.data = [...this.data]
    },
    //改价
    handlePriceAdjustment(record) {
      this.$confirm({
        title: '提示',
        content: '确定改价？',
        okText: '确定',
        cancelText: '取消',
        type: 'warning',
        onOk: () => {
          updatePriceBatch([record])
            .then((res) => {
              if (res.success) {
                this.$message.success('改价成功')
                this.initTableData()
              }
            })
            .catch((error) => {
              this.$message.error(error.message || '改价失败')
            })
        },
      })
    },
    //批量改价
    handleBulkRepricing() {
      if (!this.selectedRowKeys.length) {
        return this.$message.info('请选择需要批量改价的商品')
      }
      this.$confirm({
        title: '提示',
        content: '确定批量改价？',
        okText: '确定',
        cancelText: '取消',
        type: 'warning',
        onOk: () => {
          let params = this.data.filter((item) => this.selectedRowKeys.includes(item.id))
          updatePriceBatch(params)
            .then((res) => {
              if (res.success) {
                this.selectedRowKeys = []
                this.$message.success('批量改价成功')
                this.initTableData()
              }
            })
            .catch((error) => {
              this.$message.error(error.message || '批量改价失败')
            })
        },
      })
    },

    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    confirmDeleteSpecValue(record) {
      deleteGoodsSpecValue({
        id: record.goodsSpecValueId,
      }).then((res) => {
        if (res.success) {
          this.$message.success('商品规格属性删除成功')
          this.querySpecvalueListBySpecId()
        }
      })
    },
    confirmDeleteSpec(record) {
      deleteGoodsSpec({
        id: record.goodsSpecId,
      }).then((res) => {
        if (res.success) {
          this.$message.success('商品规格删除成功')
          this.findCommoditySpecList()
        }
      })
    },
    showModal2(record) {
      this.currentSpecItem = record
      this.querySpecvalueListBySpecId()
      this.visible2 = true
    },

    async querySpecvalueListBySpecId() {
      const data = await querySpecValueListBySpcId({
        goodsSpecId: this.currentSpecItem.goodsSpecId,
      })
      if (data.code == 200) {
        this.specValueDatas = data.data
      }
    },

    async findCommoditySpecList() {
      var params = {
        page: this.specPage,
        queryParam: this.currentItem.id,
      }
      const data = await querySpecListByCommodityId({
        goodsId: this.currentItem.id,
      })
      if (data.code == 200) {
        this.specDatas = data.data
      }
    },
    // 获取商品分类及其子列表信息
    getCommodityClassList() {
      findCategoryListByStore().then((res) => {
        //处理分类数据
        this.classList = res.data
      })
    },
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    searchTableData() {
      this.initTableData()
    },
    getList() {
      this.loading = true
      let queryParam = this.queryParam
      let beginTime = ''
      let endTime = ''
      if (queryParam.time && queryParam.time.length == 2) {
        beginTime = this.$dayjs(queryParam.time[0]._d).format('YYYY-MM-DD HH:mm:ss')
        endTime = this.$dayjs(queryParam.time[1]._d).format('YYYY-MM-DD HH:mm:ss')
      }
      const params = {
        page: this.page,
        queryParam: { ...queryParam, areaId: this.areaId, beginTime, endTime },
      }
      goodsSpecPage(params)
        .then((res) => {
          if (res.success) {
            this.loading = false
            this.data = res.data.rows
            this.originalData = res.data.rows
            this.total = res.data.totalRows
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    // 删除
    sysItemDelete(record) {
      this.recordItem = record
      this.disableCommodityCate(2) //2:删除
    },
    changeBanned(event, record) {
      let content = event ? '确定下架当前商品？' : '确定取消下架当前商品？'
      let msgContent = event ? '下架当前商品成功' : '取消下架当前商品成功'
      let errorContent = event ? '下架当前商品失败' : '取消下架当前商品失败'
      this.$confirm({
        title: '提示',
        content,
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          console.log('OK')
          platformLowerShelfGoods({ goodsId: record.id, isLowerShelf: event ? 2 : 1 })
            .then((res) => {
              if (res.success) {
                this.$message.success(msgContent)
                this.getList()
              }
            })
            .catch((error) => {
              this.$message.error(error.message || errorContent)
            })
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    },
    clickSwitch(record) {
      this.recordItem = record
    },
    changeSwitch(e) {
      let that = this
      let delFlag = e ? 1 : 3
      setTimeout(function () {
        that.disableCommodityCate(delFlag)
      }, 300)
    },
    async disableCommodityCate(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await commodityDelete(params)
      var text = delFlag == 1 ? '上架' : delFlag == 2 ? '删除' : '下架'
      if (data.code == 200) {
        this.$message.success(this.recordItem.name + ' 已' + text)
        this.getList()
      }
    },
    //添加商品页面路由跳转
    addCommodity() {
      this.$router.push({
        path: `/addCommodity?areaId=${this.areaId}`,
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .row-success {
  background-color: #d2f2c4;
}
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 30px;
  height: 30px;
}
</style>
