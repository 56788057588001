<template>
  <a-modal
      title="同步商品规格"
      width="50%"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item
                label="区域"
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                has-feedback
            >
              <a-select
                  mode="multiple"
                  v-decorator="['areaIdList', { rules: [{ required: true, message: '请选择区域！' }] ,initialValue:dataList.map(item=>item.id)}]"
                  allow-clear
                  placeholder="请选择"
              >
                <a-select-option v-for="(item, index) in dataList" :key="index" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
                label="需要同步的字段"
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                has-feedback
            >
              <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
                全部
              </a-checkbox>
              <a-checkbox-group v-decorator="['fields', { rules: [{ required: true, message: '请选择需要同步的字段！' }]}]" :options="fieldOptions" @change="onChange"/>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import {areaPage} from '@/api/modular/mallLiving/area'
import {updatePriceToArea} from "@/api/modular/mallLiving/commodity/commodityList"; //编辑和详情接口
export default {
  name: 'SynchronizationForm',
  data() {
    return {
      labelCol: {
        xs: {span: 24},
        sm: {span: 3},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 14},
      },
      indeterminate: false,
      checkAll: true,
      //商品规格信息
      specification: {},
      // 区域列表
      dataList: [],
      //同步的规格字段
      fields: [],
      specificationFields: {
        name: '规格名称',
        image: '封面图',
        price: '售价',
        costPrice: '成本价',
        minPrice: '最低价',
        stock: '库存',
        commissionRatio: '业务员卖出平台的商品，利润拿出去和代理商分的比例',
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
    }
  },
  computed: {
    fieldOptions() {
      let arr = [];
      let specificationFields = this.specificationFields
      let specification = this.specification
      for (const specificationKey in specification) {
        if (specificationFields[specificationKey] && specification.hasOwnProperty(specificationKey)) {
          arr.push({label: specificationFields[specificationKey], value: specificationKey})
        }
      }
      return arr
    }
  },
  mounted() {
    this.getAreaList()
  },
  methods: {
    onChange(checkedList) {
      let fieldOptions = this.fieldOptions
      this.indeterminate = !!checkedList.length && checkedList.length < fieldOptions.length;
      this.checkAll = checkedList.length === fieldOptions.length;
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        indeterminate: false,
        checkAll: e.target.checked,
      });
      this.form.setFieldsValue({'fields': e.target.checked ? this.fieldOptions.map(item=> item.value) : []})
    },
    // 初始化方法
    init(record) {
      //下面是正常的初始化
      this.specification = record
      this.visible = true

      if (record && typeof record === 'object') {
        let specificationFields = this.specificationFields
        let fields = []
        for (const key in record) {
          if (specificationFields[key] && record.hasOwnProperty(key)) {
            fields.push(key)
          }
        }
        this.$nextTick(()=>{
          this.form.setFieldsValue({'fields': fields})
        })
      }

    },
    getAreaList() {
      let query = {
        queryParam: {},
        page: {
          pageNo: 1,
          pageSize: 1000000,
        },
      }
      areaPage(query).then((res) => {
        if (res.success) {
          this.dataList = res.data.rows
        }
      })
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          const {areaIdList,fields} = values
          let specification= this.specification
          const{goodsName,id,goodsId} = specification
          let obj = {
            id,
            goodsId,
            goodsName,
          }
          fields.forEach(field=>{
            obj[field] = specification[field]
          })

          // 调取编辑接口
          updatePriceToArea({specifications: obj, areaIdList}).then((res) => {
            if (res.success) {
              this.$message.success('同步成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.checkAll = true
      this.indeterminate = false
      this.animationList = []
      this.form.resetFields() //重置表单
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
